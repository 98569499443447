import React from 'react'
import { Link } from 'react-router-dom'
import { CardData } from '../components/CardData'
import CardPopular from '../components/CardPopular'

const PopularCourse = () => {

    return (

        <div className='row px-1 mt-2'>
            {
                CardData.map((item) => {
                    return (

                        <CardPopular
                            title={item.title}
                            key={item.id}
                            image={item.image}
                            price={item.price}
                            star1={item.star}
                            star2={item.star}
                            star3={item.star}
                            star4={item.starHalf}
                            star5={item.starOutline}
                        />

                    )
                })
            }
        </div>

    )
}

export default PopularCourse