


function LegalTerms() {
    return (
        <>
            <div class="section mt-5 pt-4 mb-3">
                <div class="card">
                    <div class="card-body">
                        <h2 class="card-title">Condition of attending</h2>
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consectetur reiciendis cupiditate inventore. Veniam distinctio delectus labore quae aperiam eum. Impedit vero aut provident rem beatae minus facilis vel omnis odit.
                    </div>
                </div>
            </div>

            <div class="section mt-3 mb-3">
                <div class="card">
                    <div class="card-body">
                        <h2 class="card-title">Terms of use</h2>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium quae dolor, magnam necessitatibus cumque quasi saepe perferendis fuga hic sunt assumenda, aut, tenetur esse provident vitae nulla deleniti quisquam illo quos ex! Consequuntur, molestias! Sint neque eius et molestias esse placeat corrupti, est reiciendis dolor culpa nihil id assumenda maxime commodi iusto sequi impedit voluptas quos. Architecto, nemo, atque aspernatur nobis quo debitis quasi culpa repellendus amet facilis vero quibusdam tempore excepturi quisquam magnam placeat. Atque numquam distinctio asperiores illo!
                    </div>
                </div>
            </div>
        </>
    )
}
export default LegalTerms;