import { bindActionCreators } from "@reduxjs/toolkit";
import { useState } from "react";
import "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { chagneUserEmail, ChagneUserName, setUserreferByData, submitProfileForm } from "../action/CommonActions";
import LatestUserData from "../action/LatestUserData";
import { profile_pic_path } from "../define/Define";
import { ActionCreator } from "../state";

function Profile() {
    const dispatch = useDispatch();
    const Logouter = bindActionCreators(ActionCreator, dispatch);
    const WinBalance = useSelector(state => state.WinWallet);
    const user_data = LatestUserData();
    const navigate = useNavigate();
    const [UserName, setUserName] = useState(user_data.uname);
    const [UserEmail, setUserEmail] = useState(user_data.uemail);
    const [UserRefer_by, setUserRefer_by] = useState(user_data.refer_by ? user_data.refer_by : "");
    const [UserReferStatus, setUserReferStatus] = useState(user_data.refer_by);
    const [currentProfilePic, setcurrentProfilePic] = useState(profile_pic_path + user_data.profile_pic);
    const [profilePicFormdata, setprofilePicFormdata] = useState("");
    const [showModal, setshowModal] = useState({ email: false, profile: false });

    const handleModalActions = (obj) => {
        setshowModal(obj);
    };
    const onProfileImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setcurrentProfilePic(URL.createObjectURL(event.target.files[0]));
            setprofilePicFormdata({ fileName: event.target.files[0] });
        }
    }
    const setProfileOnClick = (event) => {
        setcurrentProfilePic(event.target.src);
        setprofilePicFormdata({ fileName: event.target.src })
    }

    const Logout = () => {

        Logouter.login(false);
        secureLocalStorage.removeItem("refferal_id");
        secureLocalStorage.removeItem("user_data");
        secureLocalStorage.removeItem("login_id");
        navigate('/', { replace: false });
    }

    return (
        <>

            <div id="appCapsule" className='pb-0 pt-5'>

                <div className="section mt-3 text-center">
                    <div className="avatar-section">
                        <div style={{ position: 'relative' }} >
                            <img
                                className="imaged w100 rounded"
                                src={currentProfilePic}
                                onError={() => setcurrentProfilePic(profile_pic_path + "placeholder.png")}
                                onClick={(e) => { setProfileOnClick(e); handleModalActions({ email: false, profile: true }) }}
                                height="80px" width="80px" id="profile_pic" alt="Profile Pic"
                            />
                            <div style={{ position: "absolute", top: "0", display: 'grid', placeItems: 'center' }}
                                onClick={() => { document.getElementById("profile_pic").click(); }} className="bg_opacity_overlay text-white  w-100 bg-dark border-50 h-100">
                                <span>Change-</span> <span>Profile </span>
                            </div>
                        </div>

                    </div>
                    <div>
                        <p className='m-0' style={{ color: 'black', fontWeight: '500' }}>Samantha</p>
                        <p className='m-0' style={{ fontSize: '12px' }}><span>+91</span> {user_data.phone}</p>
                    </div>
                </div>

            </div>



            {/* ======================================== */}

            <div id="appCapsule pt-0 pb-0" className='bg-white shadow'>
                <div className="section full mt-1 mb-2">
                    <div className="wide-block pt-2 pb-3">

                        {/* ===================== These below lines should be changed ========================== */}

                        <div>
                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <label className="label" htmlFor="name5">Name</label>
                                    <input aria-invalid="false" type="text" onChange={e => setUserName(e.target.value)} value={UserName} className="form-control" />
                                </div>
                            </div>

                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <label className="label" htmlFor="email5">E-mail</label>
                                    <input type="email" className="form-control" id="email5" placeholder="E-mail address" />
                                </div>
                            </div>

                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <label className="label" htmlFor="password5">Date Of Birth</label>
                                    <input type="date" className="form-control" id="password5" />
                                </div>
                            </div>

                            <div className="form-group boxed mt-3 pb-0">
                                <div className="input-wrapper">
                                    <button className='btn text-white w-100 update-btn' data-toggle="modal" data-target="#DialogIconedSuccess">Update</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {/* ===================================== */}
            {/* Profile Update Success Toast */}

            <div class="modal fade dialogbox" id="DialogIconedSuccess" data-backdrop="static" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-icon text-success">
                            <ion-icon name="checkmark-circle"></ion-icon>
                        </div>
                        <div class="modal-header">
                            <h5 class="modal-title">Success</h5>
                        </div>
                        <div class="modal-body">
                            Profile Updated Successfully
                        </div>
                        <div class="modal-footer">
                            <div class="btn-inline">
                                <a href="#" class="btn" data-dismiss="modal">CLOSE</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* =============================================== */}

            <div className="main-area" style={{ "paddingTop": "60px" }}>
                <div className="p-3" style={{ "background": "rgb(250, 250, 250)" }}>
                    <div className="center-xy py-2">
                        <picture style={{ position: "relative" }}>
                            <img className="border-50" src={currentProfilePic}
                                onError={() => setcurrentProfilePic(profile_pic_path + "placeholder.png")}
                                onClick={(e) => { setProfileOnClick(e); handleModalActions({ email: false, profile: true }) }}
                                height="80px" width="80px" id="profile_pic" alt="Profile Pic"
                            />
                            <div style={{ position: "absolute", top: "0", }} onClick={() => { document.getElementById("profile_pic").click(); }} className="bg_opacity_overlay text-white d-flex align-items-center justify-content-center w-100 bg-dark border-50 h-100">
                                <span>Change-</span> <span>Profile </span>
                            </div>
                        </picture>
                        <span className="battle-input-header mr-1">+91 {user_data.phone}</span>
                        <div className="text-bold my-3" id="username_div">
                            {UserName}
                            <img className="ml-2" width="20px" id="open_user_edit" src="/assets/images/icon-edit.jpg" alt="icon-edit" />
                        </div>
                        <div className="text-bold my-3" id="edit_username_div"
                            style={{ "display": "none" }}>
                            <div className="MuiFormControl-root MuiTextField-root" style={{ "verticalAlign": "bottom" }}>
                                <label className="MuiFormLabel-root MuiInputLabel-root m-0 MuiInputLabel-formControl MuiInputLabel-animated" data-shrink="false">Enter Username</label>
                                <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl">
                                    <input aria-invalid="false" type="text" onChange={e => setUserName(e.target.value)} value={UserName} className="MuiInputBase-input MuiInput-input" />
                                    <img className="ml-2" width="20px" id="username_div_opener"
                                        onClick={() => ChagneUserName(UserName)}
                                        src="/assets/images/select-blue-checkIcon.png" alt="checkIcon"
                                    />
                                </div>
                            </div>
                        </div>
                        <Link className="d-flex align-items-center profile-wallet w-100" to="/wallet">
                            <picture className="ml-4"><img width="32px" src="/assets/images/wallet.png" alt="wallet" /></picture>
                            <div className="ml-5 mytext text-muted ">My Wallet</div>
                        </Link>
                    </div>
                </div>
                <div className="divider-x"></div>
                <div className="p-3">
                    <div className="text-bold">Complete Profile</div>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        loop={true}
                        autoplay={true}
                        pagination={{ clickable: true }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide onClick={() => handleModalActions({ email: true, profile: false })}>
                            <div className="col-4 text-center">
                                <img src="/assets/images/mail.png" className="ml-5 img-fluid" alt="mail" />
                            </div>
                            <div className="col-1"></div>
                            <div className="col-7 text-left d-flex semi-bold-text" >
                                Update Email Id
                                {
                                    UserEmail ?
                                        (
                                            <img src="/assets/images/green-check.png" width="30px" title="✔️ Email Verified !!" className="ml-3" alt="green-check" />
                                        ) :
                                        null
                                }
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => navigate("/complete-kyc")}>
                            <div className="col-4 text-center">
                                <img src="/assets/images/kyc-icon-new.png" className="ml-5 img-fluid" alt="kyc-icon-new" />
                            </div>
                            <div className="col-1"></div>
                            <div className="col-7 text-left d-flex semi-bold-text">
                                Complete KYC
                                {
                                    user_data.KycStatus === 2 ?
                                        (
                                            <img src="/assets/images/green-check.png" width="30px" className="ml-3" alt="green-check" />
                                        ) : user_data.KycStatus === 0 ?
                                            (
                                                <img src="/assets/images/red_alert.png" title="Alert!! Click For More Details." width="30px" className="ml-3" alt="red_alert" />
                                            ) : ""
                                }
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className="divider-x"></div>
                <div className="px-3 py-1">
                    <div className="d-flex align-items-center position-relative" style={{ "height": "84px" }}>
                        <picture><img height="32px" width="32px" src="/assets/images/refer.png" alt="refer" /></picture>
                        <div className="pl-4">
                            {
                                UserReferStatus ?
                                    (
                                        <>
                                            <div className="text-uppercase moneyBox-header" style={{ "fontSize": "0.8em" }}>Your Added Reffer Id Is</div>
                                            <div className="d-flex">
                                                <div className="MuiFormControl-root MuiTextField-root" style={{ "verticalAlign": "bottom" }}>
                                                    <h4>{UserRefer_by}</h4>
                                                </div>
                                            </div>
                                        </>
                                    )
                                    : (
                                        <>
                                            <div className="text-uppercase moneyBox-header" style={{ "fontSize": "0.8em" }}>Use Refer Code</div>
                                            <div className="d-flex">
                                                <div className="MuiFormControl-root MuiTextField-root" style={{ "verticalAlign": "bottom" }}>
                                                    <div
                                                        className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                                                        <input aria-invalid="false" type="text"
                                                            value={UserRefer_by}
                                                            onChange={e => setUserRefer_by(e.target.value)} className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <img className="ml-3 img-fluid mt-2" style={{ width: "20px", height: "20px" }}
                                                    onClick={() => setUserreferByData(UserRefer_by, setUserReferStatus)} src="/assets/images/select-blue-checkIcon.png" alt="select-blue-checkIcon"
                                                />
                                            </div>
                                        </>
                                    )
                            }
                        </div>
                    </div>
                </div>
                <div className="px-3 py-1">
                    <div className="d-flex align-items-center position-relative" style={{ "height": "84px" }}>
                        <picture>
                            <img height="32px" width="32px" src="/assets/images/global-cash-won-green-circular.png" alt="global-cash-won-green-circular" />
                        </picture>
                        <div className="pl-4">
                            <div className="text-uppercase moneyBox-header" style={{ "fontSize": "0.8em" }}>Cash Won</div>
                            <div>
                                <picture className="mr-1">
                                    <img height="auto" width="21px" src="/assets/images/global-rupeeIcon.png" alt="global-rupeeIcon" />
                                </picture>
                                <span className="moneyBox-text" style={{ "fontSize": "1em", "bottom": "-1px" }}>₹{WinBalance}</span>
                            </div><span className="thin-divider-x"></span>
                        </div>
                    </div>
                </div>
                <div className="px-3 py-1">
                    <div className="d-flex align-items-center position-relative" style={{ "height": "84px" }}>
                        <picture><img height="32px" width="32px" src="/assets/images/WhiteStroke.png" alt="WhiteStroke" />
                        </picture>
                        <div className="pl-4">
                            <div className="text-uppercase moneyBox-header" style={{ "fontSize": "0.8em" }}>Battle Played</div>
                            <div>
                                <span className="moneyBox-text" style={{ "fontSize": "1em", "bottom": "-1px" }}>1</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divider-x"></div>
                <div className="p-3">
                    <button className="center-xy btn btn-block btn-light text-muted text-uppercase py-4 font-weight-bolder"
                        onClick={Logout}
                    >Log Out</button>
                </div>
            </div>

            {/*//! {{ -- -- -- Update Email Modal -- -- -- }} !//*/}
            <Modal show={showModal.email} onHide={() => handleModalActions({ email: false, profile: false })}>
                <Modal.Body>
                    <h6>Update Email Id</h6>
                    <input type={"email"} className="form-control" value={UserEmail} onChange={e => setUserEmail(e.target.value)} />
                </Modal.Body>
                <Modal.Footer className="p-0 d-flex justify-content-center">
                    <Button variant="primary"
                        onClick={() => {
                            chagneUserEmail(UserEmail);
                            handleModalActions({ email: false, profile: false })
                        }}
                    >Save Changes</Button>
                </Modal.Footer>
            </Modal>
            {/*//! {{ -- -- -- Update Profile Pic Modal -- -- -- }} !//!*/}
            <Modal show={showModal.profile} onHide={() => handleModalActions({ email: false, profile: false })}>
                <Modal.Body>
                    <div className="header cxy flex-column">
                        <picture>
                            <img className="border-50" height="80px" width="80px" alt="Current Profile Pic" src={currentProfilePic} />
                        </picture>
                        <div className="custom-file mt-4">
                            <input type="file" className="custom-file-input"
                                onChange={(e) => onProfileImageChange(e)} id="profilePic" name="profilePic" accept="image/*"
                            />
                            <label className="custom-file-label" htmlFor="screenshot">Browse your profile pic</label>
                        </div>
                        <span className="mt-2">OR</span>
                        <div className="header-text mt-2">Choose Avatar</div>
                    </div>
                    <div className="mx-3 pb-3" style={{ paddingTop: "30px" }}>
                        <div className="row justify-content-between col-10 mx-auto">
                            <img height="50px" onClick={e => setProfileOnClick(e)} width="50px" src={profile_pic_path + "Avatar1.png"} alt="Avatar1" />
                            <img height="50px" onClick={e => setProfileOnClick(e)} width="50px" src={profile_pic_path + "Avatar2.png"} alt="Avatar2" />
                            <img height="50px" onClick={e => setProfileOnClick(e)} width="50px" src={profile_pic_path + "Avatar3.png"} alt="Avatar3" />
                            <img height="50px" onClick={e => setProfileOnClick(e)} width="50px" src={profile_pic_path + "Avatar4.png"} alt="Avatar4" />
                        </div>
                        <div className="row justify-content-between col-10 mx-auto mt-3">
                            <img height="50px" onClick={e => setProfileOnClick(e)} width="50px" src={profile_pic_path + "Avatar5.png"} alt="Avatar5" />
                            <img height="50px" onClick={e => setProfileOnClick(e)} width="50px" src={profile_pic_path + "Avatar6.png"} alt="Avatar6" />
                            <img height="50px" onClick={e => setProfileOnClick(e)} width="50px" src={profile_pic_path + "Avatar7.png"} alt="Avatar7" />
                            <img height="50px" onClick={e => setProfileOnClick(e)} width="50px" src={profile_pic_path + "Avatar8.png"} alt="Avatar8" />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="p-0 d-flex justify-content-center">
                    <Button variant="primary"
                        onClick={() => {
                            submitProfileForm(profilePicFormdata);
                            handleModalActions({ email: false, profile: false })
                        }}
                    >
                        Update Profile Pic
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default Profile;