import React from 'react'

const CardPopular = (props) => {
    

    return (

        <div class="d-flex flex-column  col-6">
            <img src={props.image} alt="img" class="image-block imaged w-100" style={{height:'120px'}}/>
            <div>
                <strong>{props.title.length > 18 ? props.title.substring(0, 18) + "..." : props.title }</strong><br />
                <p class='price text-danger'>{props.price}</p>
            </div>
        </div>


    )
}

export default CardPopular