import { Link } from "react-router-dom";
import { bindActionCreators } from "@reduxjs/toolkit";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { ActionCreator } from "../state";
function Login() {
    const navigate = useNavigate();
    const dispatcher = useDispatch();
    const [ReadOnly, setReadOnly] = useState("");
    const [ShowResend, setShowResend] = useState(false);
    const LoginAction = bindActionCreators(ActionCreator, dispatcher);
    /*/
       // ! {{ FormData For Login }} ! //
    /*/
    const [formData, setFormData] = useState({
        referalCode: secureLocalStorage.getItem("refferal_id"),
        mobile: "",
    });
    // console.log('FormData => ', formData);

    /*/
      // ! {{ Checking If OTP Is Sended Or Not }} ! //
    /*/
    const [otpSent, setOtpSend] = useState("");
    /*/
      // ! {{ Handling The Change for Input }} ! //
    /*/
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }


    /*/
        // ! {{ Submiting The Form }} ! //
    /*/

    const SubmitLoginForm = (e) => {
        let proceed = otpSent && formData.otp ? true : !otpSent ? true : false;
        if (proceed) {
            const submitter = () => {
                axios.post("/server/login.php", formData).then(result => {
                    let response = result.data;
                    if (response.status === 101) {
                        toast.error(response.msg);
                    } else if (response.status === 100) {
                        var timer = 30;
                        toast.success(response.msg);
                        setReadOnly(true);
                        setOtpSend(true);
                        setTimeout(() => {
                            setShowResend(true);
                            clearInterval(timerFunc);
                        }, 30000);
                        const timerFunc = setInterval(() => {
                            document.getElementById("otp_resend_timer").innerHTML = `0 : ${timer -= 1}`;
                        }, 1000);
                    } else if (response.status === 102) {
                        secureLocalStorage.setItem("login_id", response.msg.user_id);
                        secureLocalStorage.setItem("device_id", response.msg.device_id);
                        LoginAction.login(response.msg.user_id);
                        LoginAction.AddWalletAmount(response.msg.wallet, 0);
                        LoginAction.R_WalletAction(response.msg.r_wallet);
                        LoginAction.Win_WalletAction(response.msg.win_wallet)
                        setTimeout(() => {
                            toast.success("Login Successfull");
                            navigate('/login_success', { replace: true });
                        }, 1000);
                    } else if (response.status === 99) {
                        toast.error(response.msg);
                        setTimeout(() => {
                            navigate(`/user-banned/${formData.mobile}`, { replace: true });
                        }, 1000);

                    }
                    e.target.removeAttribute("disabled");
                });
            }
            submitter();
        }
        else {
            e.target.removeAttribute("disabled");
            toast.error("OTP Required");
        }
    }

    const otpResendfunc = (e) => {
        setReadOnly(false);
        setOtpSend(false);
        setShowResend(false);
        setTimeout(() => {
            document.getElementById("login_btn").click();
        }, 600);
    }

    return (

        <div className='px-2 bg-white' style={{ width: '100vw', height: 'calc(100vh - 116px)' }}>


            <div className="section w-100" style={{ overflow: 'hidden', height: 'calc(100vh - 500px)' }}>
                <img className='' src="/assets/img/bg/Mobile-login.jpg" style={{ transform: 'scale(1.4)', width: '100%', height: '100%' }} alt="" />
            </div>

            <div className="section text-center bg-white">
                <h1 className="pb-0">Welcome Back</h1>
                <h4 className='text-muted mb-0'>Log in to your existing account of e-learning</h4>
            </div>

            <div class="section">
                {/* <div class="section-title">Boxed Inputs</div> */}
                <div class="w-100">
                    <div class="mt-4">
                        <form>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <label class="label" htmlFor="txt">E-mail/Phone number</label>
                                    <input
                                        className="form-control"
                                        name="mobile" type="number"
                                        readOnly={ReadOnly} onChange={handleChange} value={formData.mobile} id="txt" fdprocessedid="6ev6qh"
                                    />
                                </div>
                            </div>
                            {otpSent ? (
                                <div class="form-group boxed">
                                    <div class="input-wrapper">
                                        <label class="label" htmlFor="email1">Enter a correct OTP</label>
                                        <input className="form-control" name="otp" type="number" onChange={handleChange} value={formData.otp ? formData.otp : ""} placeholder="Enter OTP" fdprocessedid="w59hew" />
                                    </div>
                                </div>
                            ) : null
                            }

                            {/* Resend OTP Button */}

                            {
                                otpSent ?
                                    ShowResend ? (
                                        <div className="text-right">
                                            <div className="text-danger p-0" onClick={e => { e.target.setAttribute("disabled", true); otpResendfunc(e); }} style={{cursor: 'pointer'}}>Resend OTP ?</div>
                                        </div>
                                    ) : (
                                        <>
                                            <p className="text-left text-dark p-0">Resend OTP in : <span id="otp_resend_timer" className="text-danger"></span></p>
                                        </>
                                    )
                                    : null
                            }

                            {/* Resend OTP Button */}

                            <div className="form-button-group p-0 mt-2">
                                <button type="submit" className="btn btn-primary btn-block btn-lg"
                                    id="login_btn" onClick={e => { e.target.setAttribute("disabled", true); SubmitLoginForm(e); }} fdprocessedid="nepyck">Continue</button>
                            </div>


                            <div className="form-links mt-1">
                                <div>
                                    <Link to='/registration'>Register Now</Link>
                                </div>
                                <div><a href="app-forgot-password.html" className="text-muted">Forgot Password?</a>
                                </div>
                            </div>


                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Login;