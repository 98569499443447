import React from "react";

const HeroBanner = () => {

  return (

    <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="assets/img/course/2.jpg" class="d-block w-100" alt="..." style={{ height: '250px' }} />
        </div>
        <div class="carousel-item">
          <img src="assets/img/course/3.jpg" class="d-block w-100" alt="..." style={{ height: '250px' }} />
        </div>
        <div class="carousel-item">
          <img src="assets/img/course/4.jpg" class="d-block w-100" alt="..." style={{ height: '250px' }} />
        </div>
      </div>
      <button class="carousel-control-prev d-none" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next d-none" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>



  );
};

export default HeroBanner;