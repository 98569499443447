import React from 'react'
import { Link } from 'react-router-dom'

import { CardData } from '../components/CardData'
import CardPopular from '../components/CardPopular'

const Course = () => {
  return (

      <div className='px-1 mt-2'>
        {
          CardData.map((item) => {
            return (

              <Link to={`/course-detail/${item.id}`} key={item.id} >
                <CardPopular
                  title={item.title}
                  image={item.image}
                  price={item.price}
                  star1={item.star}
                  star2={item.star}
                  star3={item.star}
                  star4={item.starHalf}
                  star5={item.starOutline}
                />
              </Link>

            )
          })
        }
      </div>
  )
}

export default Course
