import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';

const Footer = () => {

    const logged = useSelector(state => state.login);

    console.log(logged);
    

    const location = useLocation();
    const Pathname = location.pathname;
    
    const [activePage, setActivePage] = useState('');


    return (
        <>
            <div className="appBottomMenu">
                <Link to="/" className={`item ${Pathname === '/' ? 'active' : ''}`}>
                    <div className="col">
                        <ion-icon name="home-outline"></ion-icon>
                        <strong>Home</strong>
                    </div>
                </Link>
                <Link to='/search' className={`item ${Pathname === '/search' ? 'active' : ''}`}>
                    <div className="col">
                        <ion-icon name="search-outline"></ion-icon>
                        <strong>Search</strong>
                    </div>
                </Link>
                <Link to='/course' className={`item ${Pathname === '/course' ? 'active' : ''}`}>
                    <div className="col">
                        <ion-icon name="play"></ion-icon>
                        <strong>Courses</strong>
                    </div>
                </Link>
                <Link to={logged ? '/wishlist' : '/login'} className={`item ${Pathname === '/wishlist' ? 'active' : ''}`}>
                    <div className="col">
                    <ion-icon name="heart"></ion-icon>
                        <strong>Wishlist</strong>
                    </div>
                </Link>
                <Link to={logged ? '/profile-setting' : '/login'} className={`item ${Pathname === '/profile' ? 'active' : ''}`}>
                    <div className="col">
                    <ion-icon name="person"></ion-icon>
                        <strong>Profile</strong>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default Footer
