import { Link } from "react-router-dom";
import HeroBanner from '../components/HeroBanner'
import { CardData } from "../components/CardData";
import Category from '../components/Category';
import CardList from '../components/CardList'
import CardPopular from '../components/CardPopular'

function Home() {

    return (

            <div className='pb-5'>
                <HeroBanner />
                <div class="section mt-4">
                    <div class="section-heading">
                        <h2 class="title">Category</h2>
                        <Link to='/category' class="link">See all</Link>
                    </div>
                </div>


                <Category />

                <div class="section mt-4">
                    <div class="section-heading">
                        <h2 class="title">Recommended</h2>
                        <Link to='/recommended-course' class="link">See all</Link>
                    </div>
                </div>



                {
                    CardData.map((item) => {
                        return (

                            <CardList title={item.title}
                                key={item.id}
                                image={item.image}
                                price={item.price}
                                star1={item.star}
                                star2={item.star}
                                star3={item.star}
                                star4={item.starHalf}
                                star5={item.starOutline}
                                
                            />
                        )
                    })
                }
                <div class="section mt-4">
                    <div class="section-heading">
                        <h2 class="title">Popular</h2>
                        <Link to='/popular-course' class="link">See all</Link>
                    </div>
                </div>
                <div class="row px-2 items-center" >

                    {
                        CardData.slice(0, 2).map((item) => {
                            return (
                                <CardPopular
                                    key={item.id}
                                    title={item.title}
                                    image={item.image}
                                    price={item.price}
                                    star1={item.star}
                                    star2={item.star}
                                    star3={item.star}
                                    star4={item.starHalf}
                                    star5={item.starOutline}


                                />
                            )
                        })
                    }


                </div>
            </div>
    );
}

export default Home;