import React from 'react'
import { Link } from 'react-router-dom'

const CardList = (props) => {

    return (

        <Link class="transactions mb-1">


            <div class="item">
                <div class="detail">
                    <img src={props.image} alt="img" class="image-block imaged" style={{ width: '120px' }}/>
                    <div>
                        <strong>{props.title}</strong>
                        <p class='price text-danger'>{props.price}</p>
                    </div>
                </div>
            </div>

        </Link>
        
    )
}

export default CardList





{/* <a href="app-transaction-detail.html" class="item">
<div class="detail">
    <img src="3.jpg" alt="img" class="image-block imaged"  style={{width:'120px'}}/>
    <div>
        <strong>Digital Margeting</strong>
       
       
        <ion-icon name="star" style={{color:'red'}}></ion-icon>
        <ion-icon name="star" style={{color:'red'}}></ion-icon>
        <ion-icon name="star" style={{color:'red'}}></ion-icon>
        <ion-icon name="star" style={{color:'red'}}></ion-icon>
        <ion-icon name="star-outline" style={{color:'red'}}></ion-icon>
        <p class='price text-danger'>$29</p>
    </div>
</div>

</a> */}