import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useState } from "react";
import { useLocation } from "react-router-dom";

function Header() {

    const location = useLocation();

    const LoginState = useSelector(state => state.login);
    const Wallet = useSelector(state => state.Wallet);
    const Win_Wallet = useSelector(state => state.WinWallet);
    const R_Wallet = useSelector(state => state.R_Wallet);


    return (
        <>

            {/* <Sidebar /> */}



            {
                LoginState ? (

                    <div class="topHeader">
                        <div class="">
                            {location.pathname === '/' ?
                                (<Link to='/profile-setting' class="headerButton">
                                    <img src="/assets/img/sample/avatar/avatar10.jpg" alt="image" class="imaged rounded w32" />
                                </Link>
                                ) : (
                                    <button className="btn btn-outline-warning">Go Back</button>
                                )
                            }

                        </div>
                        <div class="pageTitle" >
                            <Link to='/' className="text-dark">
                                Coach
                            </Link>
                        </div>
                        <div class="">
                            <a href="#" class="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-bell-fill" viewBox="0 0 16 16">
                                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901" />
                                </svg>
                            </a>
                        </div>
                    </div>


                ) : (
                    <div class="topHeader">
                        <div class="">
                            <Link to='#' class="headerButton">
                                <img src="assets/img/sample/avatar/avatar10.jpg" alt="image" class="imaged rounded w32" />
                            </Link>
                        </div>
                        <div class="pageTitle" >
                            <Link to='/' className="text-dark">
                                Coach
                            </Link>
                        </div>
                        <div class="">
                            <a href="#" class="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-bell-fill" viewBox="0 0 16 16">
                                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901" />
                                </svg>
                            </a>
                        </div>
                    </div>

                )
            }
        </>
    )
}
export default Header;