import { useLocation } from 'react-router-dom';

const Payment = () => {
  const location = useLocation();

  return (
    <div>
      <h2 className='px-2 mt-2'>Course ID for Payment</h2>
    </div>
  );
};

export default Payment;
