import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ActionSheet from '../components/ActionSheet'
import { useParams } from 'react-router-dom';

const CourseDetail = () => {
    const { courseId } = useParams();

    const logged = useSelector(state => state.login);
    const [description, setDescription] = useState('About');
    const [openActionSheet, setOpenActionSheet] = useState(false);

    return (
        <>

            {
                openActionSheet && <ActionSheet onClose={() => setOpenActionSheet(false)} courseId={courseId} />
            }

            <div className="section full bg-white">
                <div className="subscribe bg-white shadow-lg">
                    <div className='text-center' style={{ fontWeight: '500', fontSize: '14px' }}>Lifetime Subscription <br />
                        <div className='text-center text-dark' style={{ fontWeight: '500', fontSize: '15px' }}>$ 138</div>
                    </div>
                    {logged ? (
                        <button onClick={() => setOpenActionSheet(true)}>Subscribe</button>
                    ) : (

                        <button onClick={() => setOpenActionSheet(true)}>
                            <Link to='/login' style={{color: 'white'}}>Subscribe</Link>
                        </button>

                    )}
                </div>


                {/* ================ Main Content =================== */}
                <div className="item">
                    <div className="user-car">
                        <span style={{ position: 'absolute', top: '20px', left: '20px' }}>
                            <Link to='/courses'>
                                <ion-icon name="arrow-back" style={{ fontSize: '30px', color: 'orangered' }}></ion-icon>
                            </Link>
                        </span>
                        <img src="/assets/img/bg/student-online.jpg" alt="imaged" className="" style={{ width: '100%', height: '220px' }} />
                        <h3 className='text-success text-center mt-2 pb-0 mb-0'>Course Id : {courseId}</h3>
                    </div>
                    <h1 className='mt-4 px-2'>Digital Marketing - New Course</h1>
                    <div className='mt-1 px-2' style={{ fontWeight: '500', color: 'black' }}>By Harsh Vardhan</div>
                </div>
            </div>

            <div className="section bg-white row px-2 pt-4">
                <div
                    className={`col-6 text-center ${description === 'About' ? 'description' : ''}`}
                    onClick={() => setDescription('About')}
                    style={{ cursor: 'pointer' }}
                >
                    About
                </div>
                <div
                    className={`col-6 text-center ${description === 'Video' ? 'description' : ''}`}
                    onClick={() => setDescription('Video')}
                    style={{ cursor: 'pointer' }}
                >
                    Video
                </div>
            </div>

            {/* Description Filter */}

            <div className="section bg-white pt-2" style={{ paddingBottom: '80px' }}>


                {
                    description === 'About' ?
                        (<>
                            <div className="card shadow">
                                <div className="card-body">
                                    <h2 className='mb-5' style={{ fontWeight: '600' }}>Course brief</h2>
                                    <div className="row">
                                        {/* Item */}
                                        <div className="col-5 d-flex flex-column justify-content-between">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <ion-icon name="time" style={{ color: 'orange', fontSize: '18px' }}></ion-icon>
                                                <span>Total Timing</span>
                                            </div>
                                            <div className='text-dark fw-bold' style={{ fontWeight: '500' }}>36 Hours</div>
                                        </div>
                                        {/* Item */}

                                        <div className="col-1"></div>

                                        {/* Item */}
                                        <div className="col-6 d-flex flex-column justify-content-between">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <ion-icon name="time" style={{ color: 'orange', fontSize: '18px' }}></ion-icon>
                                                <span>35 Videos</span>
                                            </div>
                                            <div className='text-dark fw-bold' style={{ fontWeight: '500' }}>Total Videos</div>
                                        </div>
                                        {/* Item */}

                                        {/* ==================================================================== */}

                                        {/* Item */}
                                        <div className="col-5 d-flex flex-column justify-content-between mt-3">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <ion-icon name="shield" style={{ color: 'orange', fontSize: '18px' }}></ion-icon>
                                                <span>Accessibility</span>
                                            </div>
                                            <div className='text-dark fw-bold' style={{ fontWeight: '500' }}>Lifetime</div>
                                        </div>
                                        {/* Item */}

                                        <div className="col-1 mt-3"></div>

                                        {/* Item */}
                                        <div className="col-6 d-flex flex-column justify-content-between mt-3">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <ion-icon name="bag-handle" style={{ color: 'orange', fontSize: '18px' }}></ion-icon>
                                                <span>Last Uploaded</span>
                                            </div>
                                            <div className='text-dark fw-bold' style={{ fontWeight: '500' }}>20 March 2020</div>
                                        </div>
                                        {/* Item */}
                                    </div>
                                </div>
                            </div>


                            <div className="card shadow my-2">
                                <div className="card-body m-0">
                                    <h3>Description</h3>
                                    <div>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Esse ducimus dicta deserunt illo. Voluptas consequuntur numquam eum recusandae, tempora beatae deserunt nobis eveniet iste inventore, necessitatibus vero quisquam alias odio rem temporibus quaerat ea maxime ipsum, nulla magnam ipsam explicabo ex cum. Hic necessitatibus aliquid voluptas delectus illo deserunt soluta.</div>
                                </div>
                            </div>
                        </>
                        )
                        :
                        (
                            <>
                                <div className="d-flex flex-column py-3">
                                    {/* Item */}
                                    <div className='d-flex align-items-start'>
                                        <ion-icon name="time" style={{ fontSize: '20px', marginTop: '4px', color: 'orange' }}></ion-icon>
                                        <div className='ml-1 d-flex flex-column text-dark' style={{ width: '85%' }}>
                                            What is Digital Marketing ? <br /><span className='text-muted'>02.56 min</span></div>
                                    </div>
                                    {/* Item */}
                                    <div className='d-flex align-items-start'>
                                        <ion-icon name="time" style={{ fontSize: '20px', marginTop: '4px', color: 'orange' }}></ion-icon>
                                        <div className='ml-1 d-flex flex-column text-dark' style={{ width: '85%' }}>
                                            Importance of Digital Marketing ? <br /><span className='text-muted'>02.56 min</span></div>
                                    </div>
                                    {/* Item */}
                                    <div className='d-flex align-items-start'>
                                        <ion-icon name="time" style={{ fontSize: '20px', marginTop: '4px', color: 'orange' }}></ion-icon>
                                        <div className='ml-1 d-flex flex-column text-dark' style={{ width: '85%' }}>
                                            How to conduct a competetive analysis ? <br /><span className='text-muted'>02.56 min</span></div>
                                    </div>
                                    {/* Item */}
                                    <div className='d-flex align-items-start'>
                                        <ion-icon name="time" style={{ fontSize: '20px', marginTop: '4px', color: 'orange' }}></ion-icon>
                                        <div className='ml-1 d-flex flex-column text-dark' style={{ width: '85%' }}>
                                            What is SEO ? <br /><span className='text-muted'>02.56 min</span></div>
                                    </div>
                                    {/* Item */}
                                    <div className='d-flex align-items-start'>
                                        <ion-icon name="time" style={{ fontSize: '20px', marginTop: '4px', color: 'orange' }}></ion-icon>
                                        <div className='ml-1 d-flex flex-column text-dark' style={{ width: '85%' }}>
                                            Defination of social media marketing and social media ? <br /><span className='text-muted'>02.56 min</span></div>
                                    </div>
                                    {/* Item */}
                                </div>
                            </>
                        )
                }
            </div>

        </>
    )
};

export default CourseDetail;
