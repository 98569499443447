import React from 'react'
import { Link } from 'react-router-dom'
import CardList from '../components/CardList'
import { CardData } from '../components/CardData'

const RecommendCourse = () => {



    return (
        <div className='mt-5'>
            {
                CardData.map((item) => {
                    return (

                        <CardList title={item.title}
                            key={item.id}
                            image={item.image}
                            price={item.price}
                        />
                    )
                })
            }
        </div>

    )
}

export default RecommendCourse