import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ActionSheet = (props) => {

    const navigate = useNavigate();

    const [paymentMethod, setPaymentMethod] = useState('Card');

    console.log(paymentMethod);
    

    const handlePaymentMethodChange = (e) => {
        setPaymentMethod(e.target.id);
    };

    const [courseId, setCourseId] = useState(props.courseId);

    const handlePayment = () => {
    navigate(`/payment/${courseId}`)
    }

    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                bottom: '0',
                left: '0',
                right: '0',
                zIndex: '100',
            }}
        >
            {/* Overlay */}
            <div
                style={{
                    height: '50%',
                    backgroundColor: 'black',
                    opacity: '0.4',
                }}
            ></div>

            {/* Modal */}
            <div className="modal-dialog m-0" role="document" style={{ height: '50%' }}>
                <div className="modal-content" style={{ height: '100%' }}>
                    <div className="modal-header align-items-center">
                        <h3 className="modal-title m-0 p-0" style={{ fontWeight: '600' }}>
                            Select your payment method
                        </h3>
                        <h5
                            className="m-0 p-0 text-danger"
                            style={{ cursor: 'pointer' }}
                            onClick={props.onClose}
                        >
                            Close
                        </h5>
                    </div>
                    <div className="modal-body">
                        <ul className="action-button-list">
                        {/* <label htmlFor="">{props.courseId}</label> */}
                            {/* Credit Card Option */}
                            <label
                                htmlFor="Card"
                                className="d-flex align-items-center border rounded p-1"
                                style={{ cursor: 'pointer' }}
                            >
                                <input
                                    type="radio"
                                    name="paymentMethod"
                                    id="Card"
                                    checked={paymentMethod === 'Card'}
                                    onChange={handlePaymentMethodChange}
                                />
                                <span className='ml-3'>Credit Card/Debit Card</span>
                            </label>

                            {/* Net Banking Option */}
                            <label
                                htmlFor="netBanking"
                                className="d-flex align-items-center border rounded p-1 mt-2"
                                style={{ cursor: 'pointer' }}
                            >
                                <input
                                    type="radio"
                                    name="paymentMethod"
                                    id="netBanking"
                                    checked={paymentMethod === 'netBanking'}
                                    onChange={handlePaymentMethodChange}
                                />
                                <span className='ml-3'>Net Banking</span>
                            </label>

                            {/* UPI Option */}
                            <label
                                htmlFor="UPI"
                                className="d-flex align-items-center border rounded p-1 mt-2"
                                style={{ cursor: 'pointer' }}
                            >
                                <input
                                    type="radio"
                                    name="paymentMethod"
                                    id="UPI"
                                    checked={paymentMethod === 'UPI'}
                                    onChange={handlePaymentMethodChange}
                                />
                                <span className='ml-3'>UPI</span>
                            </label>

                            <button
                                className="btn btn-success w-100 mt-3 p-0"
                                style={{ fontSize: '16px', height: '44px' }}
                                onClick={handlePayment}
                            >Pay with {paymentMethod === 'netBanking' ? 'Net Banking' : paymentMethod}
                            </button>

                        </ul>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActionSheet;
