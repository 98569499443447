export const CardData = [
    {
        id: 1,
        title: 'Web Development',
        price: '$29',
        image: 'assets/img/course/1.png',
        category: 'Popular',
        star: 'star',
        starHalf: 'star-half-outline',
        starOutline: 'star-outline'
    },
    {
        id: 2,
        title: 'Web Design Course',
        price: '$125',
        image: 'assets/img/course/2.jpg',
        category: 'Popular',
        star: 'star',
        starHalf: 'star-half-outline',
        starOutline: 'star-outline'
    },
    {
        id: 3,
        title: 'Mobile App Development Course',
        price: '$125',
        image: 'assets/img/course/3.jpg',
        category: 'Popular',
        star: 'star',
        starHalf: 'star-half-outline',
        starOutline: 'star-outline'
    },
    {
        id: 4,
        title: 'Flutter Course',
        price: '$125',
        image: 'assets/img/course/4.jpg',
        category: 'Popular',
        star: 'star',
        starHalf: 'star-half-outline',
        starOutline: 'star-outline'
    },
]